import { combineReducers } from 'redux';

import additionalDrivers from './modules/additionalDrivers';
import app from './modules/app/reducer';
import auth from './modules/auth';
import authorisePayment from './modules/authorisePayment';
import basket from './modules/basket';
import booking from './modules/booking/reducer';
import bookingChange from './modules/bookingChange';
import bookingConfirmation from './modules/bookingConfirmation';
import bookingDetails from './modules/bookingDetails';
import bookingReviews from './modules/bookingReviews';
import customer from './modules/customer/reducer';
import extraPayments from './modules/extraPayments';
import extras from './modules/extras';
import home from './modules/home';
import hostAllBookings from './modules/hostAllBookings';
import hostPage from './modules/hostPage';
import listing from './modules/listing/reducer';
import messagesPage from './modules/messagesPage';
import myCars from './modules/myCars';
import myTrips from './modules/myTrips';
import paymentMethods from './modules/paymentMethods/reducer';
import paymentsAndPayoutsPage from './modules/paymentsAndPayoutsPage';
import payouts from './modules/payouts';
import personalInfoPage from './modules/personalInfoPage';
import phone from './modules/phone/reducer';
import profile from './modules/profile/reducer';
import search from './modules/search/reducer';
import searchPage from './modules/searchPage/reducer';
import staticPage from './modules/staticPage/reducer';
import subscribe from './modules/subscribe/reducer';
import vehicle from './modules/vehicle/reducer';

export default combineReducers({
  additionalDrivers,
  app,
  auth,
  authorisePayment,
  basket,
  booking,
  bookingChange,
  bookingConfirmation,
  bookingDetails,
  bookingReviews,
  customer,
  extraPayments,
  extras,
  home,
  hostAllBookings,
  hostPage,
  listing,
  messagesPage,
  myCars,
  myTrips,
  paymentMethods,
  paymentsAndPayoutsPage,
  payouts,
  personalInfoPage,
  phone,
  profile,
  search,
  searchPage,
  staticPage,
  subscribe,
  vehicle,
});
