import PropTypes from 'prop-types';

import { Card, CardActionArea, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';

import { LazyContent, LinkButton } from '@evee/evee-ui.base';

import styles from './styles';

const PartnerOfferCard = ({ partner, loading, classes }) => {
  const cardJsx = (
    <Card elevation={0}>
      <CardActionArea className={classes.actionArea}>
        <LazyContent>
          <img src={partner.thumbnail} className={classes.image} alt="" />
        </LazyContent>
      </CardActionArea>

      <Typography gutterBottom align="left" style={{ marginTop: '16px', fontWeight: 'bold' }}>
        {partner.title}
      </Typography>

      <Typography paragraph align="left">
        {partner.description}
      </Typography>

      <LinkButton text={partner.cta} href={partner.url} target="_blank" />
    </Card>
  );

  const skeletonJsx = <Skeleton variant="rect" width="95%" height="190px" />;

  return loading ? skeletonJsx : cardJsx;
};

PartnerOfferCard.propTypes = {
  partner: PropTypes.shape({
    thumbnail: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string,
    urlLabel: PropTypes.string,
  }),
  loading: PropTypes.bool,
  classes: PropTypes.shape({
    actionArea: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
};

PartnerOfferCard.defaultProps = {
  offer: {},
  loading: false,
};

export default withStyles(styles)(PartnerOfferCard);
