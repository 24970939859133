import { all, call, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import { nanoid } from 'nanoid';

import { apiService, history } from '@evee/evee-ui.services';

import * as appActions from 'store/modules/app/actions';
import * as bookingDetailsActions from 'store/modules/bookingDetails';
import * as bookingDetailsSelectors from 'store/modules/bookingDetails/selectors';
import * as messagesPageActions from 'store/modules/messagesPage';
import * as messagesPageSelectors from 'store/modules/messagesPage/selectors';

const PAGE_SIZE = 25;

function getChat(hostingChats, rentingChats, chatId) {
  let chatResult = { chat: null, type: null };

  if (chatId) {
    const hostingChat = hostingChats.find((chat) => chat._id === chatId);
    if (hostingChat) {
      return { chat: hostingChat, type: 'owner' };
    }
    const rentingChat = rentingChats.find((chat) => chat._id === chatId);
    if (rentingChat) {
      return { chat: rentingChat, type: 'renter' };
    }
  }

  if (hostingChats.length > 0) {
    chatResult = { chat: hostingChats[0], type: 'owner' };
  } else if (rentingChats.length > 0) {
    chatResult = { chat: rentingChats[0], type: 'renter' };
  }

  return chatResult;
}

export function* load({ payload }) {
  try {
    const [hostingChatsResult, rentingChatsResult] = yield all([
      call(apiService.chat.getChats, 'owner', 0, PAGE_SIZE),
      call(apiService.chat.getChats, 'renter', 0, PAGE_SIZE),
    ]);

    const selectedChat = yield select(messagesPageSelectors.getSelectedChat);

    if (_.isEmpty(selectedChat)) {
      const hostingChats = hostingChatsResult.items || [];
      const rentingChats = rentingChatsResult.items || [];

      const { chat, type } = getChat(hostingChats, rentingChats, payload.chatId);

      yield put(messagesPageActions.setChatTab(type === 'renter' ? 1 : 0));

      if (chat && (payload.loadDefaultChat || payload.chatId)) {
        yield put(messagesPageActions.openChatDialog({ showChatDialog: false, chat }));
      }
    }

    yield put(
      messagesPageActions.loadSuccess({
        rentingChats: rentingChatsResult,
        hostingChats: hostingChatsResult,
      }),
    );
  } catch (error) {
    yield put(messagesPageActions.loadFailed());
    yield put(appActions.showError(error.message));
  }
}

export function* loadChat({ payload: { chat } }) {
  try {
    if (chat.id) {
      const messages = yield call(apiService.chat.getMessages, chat.id);
      const bookingId = yield select(bookingDetailsSelectors.getBookingId);

      if (bookingId !== chat.booking.id) {
        yield put(bookingDetailsActions.load(chat.booking.id));
      }

      yield put(messagesPageActions.loadChatSuccess({ messages }));

      const notificationIds = _.compact(messages.map((m) => m.notificationId));
      if (notificationIds.length) {
        yield call(apiService.notification.removeMany, notificationIds);
        yield put(appActions.decrementNewMessagesCount(notificationIds.length));
      }
    } else {
      yield put(messagesPageActions.loadChatSuccess({ messages: [] }));
    }
  } catch (error) {
    yield put(messagesPageActions.loadChatFailed());
    yield put(appActions.showError(error.message));
  }
}

export function* loadMore({ payload: { chatType, pageNumber } }) {
  try {
    const chats = yield call(apiService.chat.getChats, chatType, pageNumber - 1, PAGE_SIZE);
    yield put(
      messagesPageActions.loadMoreSuccess({ type: chatType, pageNumber, chats: chats.items }),
    );
  } catch (error) {
    yield put(messagesPageActions.loadMoreFailed());
  }
}

export function* uploadFiles({ payload: files }) {
  try {
    yield all(
      files.map((file) =>
        call(function* upload() {
          const ext = file.name.split('.').pop();
          const nanoName = `${nanoid()}.${ext}`;
          const url = `${process.env.REACT_APP_FILES_DOMAIN}/chat/${nanoName}`;
          yield call(apiService.chat.uploadFile, url, file.type, file.data);
          yield put(messagesPageActions.addFile({ ...file, url, data: undefined }));
        }),
      ),
    );

    yield put(messagesPageActions.sendMessage());
    yield put(messagesPageActions.uploadFilesSuccess());
  } catch (error) {
    yield put(messagesPageActions.uploadFilesFailed());
    yield put(appActions.showRequestError());
  }
}

export function* sendMessage() {
  try {
    const [selectedChat, message, files] = yield all([
      select(messagesPageSelectors.getSelectedChat),
      select(messagesPageSelectors.getMessage),
      select(messagesPageSelectors.getFiles),
    ]);

    if (!message && files.length === 0) {
      return;
    }

    const resultMessage = yield call(
      apiService.chat.sendMessage,
      selectedChat.id,
      message,
      files.map((file) => file.url),
    );

    if (!resultMessage) {
      throw new Error('Failed to send message');
    }

    yield put(messagesPageActions.sendMessageSuccess(resultMessage));
    yield put(messagesPageActions.setMessage(''));
    yield put(messagesPageActions.setFiles([]));
  } catch (error) {
    yield put(appActions.showRequestError());
    yield put(messagesPageActions.sendMessageFailed());
  } finally {
    // todo: remove this
    yield put(messagesPageActions.sendMessageFailed());
  }
}

function getMimeType(url) {
  const extension = url.split('.').pop();
  switch (extension) {
    case 'pdf':
      return 'application/pdf';
    case 'jpeg':
    case 'jpg':
      return 'image/jpeg';
    case 'doc':
    case 'docx':
      return 'application/msword';
    default:
      return null;
  }
}

function* safeDeleteFiles(files) {
  yield all(
    files.map((fileUrl) =>
      call(function* deleteFile() {
        const contentType = getMimeType(fileUrl);
        if (contentType) {
          yield call(apiService.chat.deleteFile, fileUrl, contentType);
        }
      }),
    ),
  );
}

export function* deleteMessage({ payload }) {
  try {
    const { _id, files } = payload;
    const selectedChat = yield select(messagesPageSelectors.getSelectedChat);
    yield call(apiService.chat.deleteMessage, selectedChat.id, _id);
    yield call(safeDeleteFiles, files);
    yield put(messagesPageActions.deleteMessageSuccess(_id));
  } catch (error) {
    yield put(appActions.showError(error.message));
    yield put(messagesPageActions.deleteMessageFailed());
  }
}

export function* openChatDialog({ payload: { showChatDialog, chat } }) {
  try {
    if (showChatDialog) {
      history.push(`${window.location.pathname}/chat/${chat.id}/${chat.booking.id}`);
    } else {
      yield put(messagesPageActions.loadChat({ chat }));
      history.replace(`/account/messages/chat/${chat.id}/${chat.booking.id}`);
    }
  } catch (error) {
    yield put(appActions.showError(error.message));
  }
}

export function* deleteNotification({ payload }) {
  try {
    if (payload) {
      yield call(apiService.notification.remove, payload);
      yield put(appActions.decrementNewMessagesCount());
    }
  } catch (error) {
    yield put(messagesPageActions.deleteNotificationFailed());
  }
}
