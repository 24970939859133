import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Region } from '@evee/evee-ui.enums';

import useCfclick from 'common/hooks/useCfclick';

import AuthorizedRouteSignIn from './AuthorizedRouteSignIn';
import HomePage from './HomePage';
import { specialOffers } from './HomePage/utils';

const HostPage = lazy(() => import('./HostPage'));
const VehicleDetails = lazy(() => import('./VehicleDetails'));
const ListingPage = lazy(() => import('./ListingPage'));
const SearchPage = lazy(() => import('./SearchPage'));
const MyTripsPage = lazy(() => import('./Profile/MyTripsPage'));
const MyCarsPage = lazy(() => import('./Profile/MyCarsPage'));
const BookingDetailsPage = lazy(() => import('./Profile/BookingDetailsPage'));
const StaticPage = lazy(() => import('./StaticPage'));
const CheckoutPage = lazy(() => import('./CheckoutPage'));
const GiftCheckoutPage = lazy(() => import('./GiftCheckoutPage'));
const HostAllBookingsPage = lazy(() => import('./Profile/HostAllBookingsPage'));
const AccountNavigationPage = lazy(() => import('./Profile/EditProfile/AccountNavigationPage'));
const PersonalInfoPage = lazy(() => import('./Profile/EditProfile/PersonalInfoPage'));
const PaymentsAndPayoutsPage = lazy(() => import('./Profile/EditProfile/PaymentsAndPayoutsPage'));
const ProfilePage = lazy(() => import('./Profile/ProfilePage'));
const LoginAndSecurityPage = lazy(() => import('./Profile/EditProfile/LoginAndSecurityPage'));
const ReferralsPage = lazy(() => import('./Profile/EditProfile/ReferralsPage'));
const NotificationsPage = lazy(() => import('./Profile/EditProfile/NotificationsPage'));
const DeactivatedAccountPage = lazy(() => import('./Profile/EditProfile/AccountDeactivatedPage'));
const BookingConfirmationPage = lazy(() => import('./BookingConfirmationPage'));
const MessagesPage = lazy(() => import('./Profile/MessagesPage'));

const RootRouter = () => {
  useCfclick();

  const specialOfferPaths = Object.keys(specialOffers);

  return (
    <Switch>
      {/* TODO: delete /host route */}
      <Route exact path="/host" render={() => <Redirect to="/car-host" />} />
      <Route exact path="/car-host" component={HostPage} />
      <Route exact path="/insurance" component={StaticPage} />
      <Route exact path="/terms" component={StaticPage} />
      <Route exact path="/how-it-works" component={StaticPage} />
      <Route exact path="/pricing" component={StaticPage} />
      <Route exact path="/privacy" component={StaticPage} />
      <Route exact path="/competition-terms" component={StaticPage} />
      <Route exact path="/coupons" component={StaticPage} />
      <AuthorizedRouteSignIn path="/checkout" component={CheckoutPage} />
      <AuthorizedRouteSignIn path="/listing/process" component={ListingPage} />
      <AuthorizedRouteSignIn path="/listing/:id" component={ListingPage} />
      <AuthorizedRouteSignIn path="/account/edit" component={AccountNavigationPage} />
      <AuthorizedRouteSignIn
        path="/account/payments-and-payouts"
        component={PaymentsAndPayoutsPage}
      />
      <AuthorizedRouteSignIn path="/account/personal-info" component={PersonalInfoPage} />
      <AuthorizedRouteSignIn path="/account/login-and-security" component={LoginAndSecurityPage} />
      <AuthorizedRouteSignIn path="/account/referrals" component={ReferralsPage} />
      <AuthorizedRouteSignIn path="/account/notifications" component={NotificationsPage} />
      <AuthorizedRouteSignIn path="/account/trips" component={MyTripsPage} />
      <AuthorizedRouteSignIn path="/account/cars" component={MyCarsPage} />
      <AuthorizedRouteSignIn path="/account/messages" component={MessagesPage} />
      <AuthorizedRouteSignIn path="/account/all-bookings" component={HostAllBookingsPage} />
      <AuthorizedRouteSignIn path="/account/bookings/:id" component={BookingDetailsPage} />
      <AuthorizedRouteSignIn path="/account/deactivate/:id" component={DeactivatedAccountPage} />
      <AuthorizedRouteSignIn path="/bookingConfirmation" component={BookingConfirmationPage} />
      <Route path="/account/:id" component={ProfilePage} />
      <Route path="/gift" component={GiftCheckoutPage} />
      <Route path="/vehicles/*/:id" component={VehicleDetails} />
      <Route path="/vehicles/:id" component={VehicleDetails} />
      <Route path="/vehicles" component={SearchPage} />
      <Route path="/search" component={SearchPage} />
      <Route exact path="/" component={HomePage} />
      <Route path="/auth/password/reset" component={HomePage} />
      <Route path="/signup" component={HomePage} />

      {specialOfferPaths.map((e, index) => (
        <Route key={index} path={`/${e}`} component={HomePage} />
      ))}

      <Route
        path="*"
        render={() => {
          if (window.location.origin === `https://${Region.newZealand.domain}`) {
            window.location.href = `https://${process.env.REACT_APP_DOMAIN_NZ}/404`;
            return;
          }

          window.location.href = `https://${process.env.REACT_APP_DOMAIN_AU}/404`;
        }}
      />
    </Switch>
  );
};

export default RootRouter;
