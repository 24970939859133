const prefix = 'app';

export const APP_INIT = `${prefix}/APP_INIT`;
export const APP_INIT_COMPLETE = `${prefix}/APP_INIT_COMPLETE`;

export const SHOW_ERROR = `${prefix}/SHOW_ERROR`;
export const SHOW_SUCCESS = `${prefix}/SHOW_SUCCESS`;
export const CLOSE_TOAST = `${prefix}/CLOSE_TOAST`;
export const SET_LOADING = `${prefix}/SET_LOADING`;
export const ACCEPT_COOKIES = `${prefix}/ACCEPT_COOKIES`;
export const SET_HEADER_NOTIFICATION = `${prefix}/SET_HEADER_NOTIFICATION`;

export const SET_REGION = `${prefix}/SET_REGION`;
export const SET_CURRENCY = `${prefix}/SET_CURRENCY`;

export const SET_NEW_MESSAGES_COUNT = `${prefix}/SET_NEW_MESSAGES_COUNT`;
export const INCREMENT_NEW_MESSAGES_COUNT = `${prefix}/INCREMENT_NEW_MESSAGES_COUNT`;
export const DECREMENT_NEW_MESSAGES_COUNT = `${prefix}/DECREMENT_NEW_MESSAGES_COUNT`;
