import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { AppBar, ButtonBase, Toolbar } from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';

import { history } from '@evee/evee-ui.services';

import LogoIcon from 'common/icons/Logo';
import useHideWebWidget from 'common/hooks/useHideWebWidget';

import HeaderNotification from 'widgets/layout/HeaderNotification';

import HamburgerButton from '../../utils/HamburgerMenu/HamburgerButton';
import MobileMenu from '../../Menu/MobileMenu';
import SearchDialog from './SearchDialog';
import styles from './styles';

const MobileHeader = ({ classes }) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const [pathName, setPathName] = useState();
  const theme = useTheme();

  useHideWebWidget(menuOpened);

  useEffect(() => {
    setPathName(window.location.pathname);
  }, []);

  return (
    <>
      <AppBar
        position="sticky"
        className={classes.defaultAppBar}
        style={{
          boxShadow: menuOpened && 'none',
        }}
      >
        {pathName === '/' &&
          moment().isBetween('2024-03-26T12:00:00+11:00', '2024-05-09T22:00:00+10:00') && (
            <HeaderNotification />
          )}

        <Toolbar className={classes.toolbar}>
          <ButtonBase style={{ marginRight: '16px' }} onClick={() => history.push('/')}>
            <LogoIcon htmlColor={theme.palette.primary.main} className={classes.logoIcon} />
          </ButtonBase>

          {!menuOpened && <SearchDialog />}

          <HamburgerButton
            color="primary"
            className={classes.menuButton}
            onClick={() => {
              setMenuOpened((value) => !value);
            }}
          />
        </Toolbar>
      </AppBar>

      <MobileMenu
        menuOpened={menuOpened}
        onClick={() => setMenuOpened(false)}
        onClose={() => setMenuOpened(false)}
      />
    </>
  );
};

MobileHeader.propTypes = {
  classes: PropTypes.shape({
    toolbar: PropTypes.string.isRequired,
    defaultAppBar: PropTypes.string.isRequired,
    logoIcon: PropTypes.string.isRequired,
    menuButton: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(MobileHeader);
