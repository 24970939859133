import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { MapOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import { Divider } from '@evee/evee-ui.base';
import { getHomeUrl } from '@evee/evee-ui.utils';
import { history } from '@evee/evee-ui.services';

import supportHrefs from 'common/constants/supportHrefs';
import useRegion from 'store/modules/app/hooks/useRegion';

import Item from '../../utils/Item';

import {
  Bolt,
  Car,
  File,
  Gift,
  Headphones,
  Lightbulb,
  Notebooks,
  SignIn,
  UserPlus,
} from '../../icons';

import styles from '../styles';

const UnauthorizedItems = ({ classes, onSignUp, onSignIn, onClose }) => {
  const location = useLocation();
  const { region } = useRegion();

  return (
    <>
      <Item
        bold
        label="Sign Up"
        icon={<UserPlus />}
        onClick={() => {
          onSignUp();
        }}
      />

      <Item
        label="Log In"
        icon={<SignIn />}
        onClick={() => {
          onSignIn();
        }}
      />

      <Item
        selected={location.pathname === '/car-host'}
        label="Become a host"
        icon={<Car />}
        onClick={() => {
          history.push('/car-host');
          onClose();
        }}
      />

      <Divider className={classes.divider} />

      <Item
        label="All evee vehicles"
        selected={location.pathname.includes('/vehicles') || location.pathname.includes('/search')}
        icon={<MapOutlined />}
        onClick={() => {
          history.push('/vehicles');
          onClose();
        }}
      />

      <Item
        label="How evee works"
        icon={<Lightbulb />}
        onClick={() => {
          window.location.href = `${getHomeUrl(region)}/renting`;
        }}
      />

      <Item
        label="Insurance"
        icon={<File />}
        onClick={() => {
          window.location.href = `${getHomeUrl(region)}/insurance`;
        }}
      />

      <Item
        label="Charging guide"
        icon={<Bolt />}
        onClick={() => {
          window.location.href = `${getHomeUrl(region)}/blog/your-evee-intro-guide-to-charging`;
        }}
      />

      <Item
        label="Gift cards"
        icon={<Gift />}
        onClick={() => {
          window.location.href = `${getHomeUrl(region)}/giftcards`;
        }}
      />

      <Divider className={classes.divider} />

      <Item
        label="Contact support"
        icon={<Headphones />}
        onClick={() => {
          window.location.href = supportHrefs[region.id].contactSupport;
        }}
      />

      <Item
        label="Help Centre"
        icon={<Notebooks />}
        onClick={() => {
          window.location.href = supportHrefs[region.id].helpCenter;
        }}
      />
    </>
  );
};

UnauthorizedItems.propTypes = {
  classes: PropTypes.shape({
    divider: PropTypes.string.isRequired,
  }).isRequired,
  onSignUp: PropTypes.func,
  onSignIn: PropTypes.func,
  onClose: PropTypes.func,
};

UnauthorizedItems.defaultProps = {
  onSignUp: () => {},
  onSignIn: () => {},
  onClose: () => {},
};

export default withStyles(styles)(UnauthorizedItems);
