import PropTypes from 'prop-types';

import { Badge } from '@material-ui/core';

import useNewMessagesCount from 'store/modules/app/hooks/useNewMessagesCount';

const NewMessagesCount = ({ hidden, children, ...rest }) => {
  const newMessagesCount = useNewMessagesCount();
  return (
    <Badge
      invisible={hidden || !newMessagesCount}
      badgeContent={newMessagesCount}
      color="secondary"
      max={9}
      {...rest}
    >
      {children}
    </Badge>
  );
};

NewMessagesCount.propTypes = {
  hidden: PropTypes.bool,
  children: PropTypes.node,
};

NewMessagesCount.defaultProps = {
  hidden: false,
  children: null,
};

export default NewMessagesCount;
