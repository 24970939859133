import { call, cancel, fork, put, select, take } from 'redux-saga/effects';
import { loadStripe } from '@stripe/stripe-js';

import { Currency, Region } from '@evee/evee-ui.enums';
import { analytics, apiService, cookies, google, socket, storage } from '@evee/evee-ui.services';

import * as appActions from 'store/modules/app/actions';
import * as authActionTypes from 'store/modules/auth';
import * as authSelectors from 'store/modules/auth/selectors';
import * as messagesPageActions from 'store/modules/messagesPage';
import * as messagesPageSelectors from 'store/modules/messagesPage/selectors';
import { setIsSubscribed, setSignedIn } from 'store/modules/auth';
import { getRegion } from 'store/modules/app/selectors';
import { setProfile } from 'store/modules/profile/actions';
import { setSignInFieldValue } from 'store/modules/auth/signIn';

export function* fillSavedCreds() {
  const savedCreds = yield call(storage.creds.get);
  if (savedCreds) {
    yield put(setSignInFieldValue({ fieldName: 'email', fieldValue: savedCreds.email }));
    yield put(setSignInFieldValue({ fieldName: 'password', fieldValue: savedCreds.password }));
    yield put(setSignInFieldValue({ fieldName: 'rememberMe', fieldValue: true }));
  }
}

export function* checkAndSetCurrentUser() {
  try {
    const storedUser = yield call(storage.user.get);
    if (!storedUser) {
      return;
    }

    const profile = yield call(apiService.customer.me);

    // todo: uncomment after visual notifications release
    //
    // const storedToken = yield call(storage.token.get);
    // if (
    //   !storedToken ||
    //   !storedToken.value ||
    //   !storedToken.expiration ||
    //   new Date() >= new Date(storedToken.expiration)
    // ) {
    //   yield call(apiService.customer.signout);
    //   return;
    // }

    yield put(setSignedIn(true));
    yield put(setProfile(profile));
    // yield put(setProfile(storedUser));
  } catch (error) {
    yield call(apiService.customer.signout);
    yield put(setSignedIn(false));
  }
}

export function* checkAndSetCookiesAccepted() {
  const accepted = yield call(cookies.isCookiesAccepted);
  if (!accepted) {
    return;
  }

  yield put(appActions.acceptCookies());
}

export function* checkAndSetHeaderNotification() {
  const region = yield select(getRegion);

  if (region?.id === Region.australia.id) {
    yield put(appActions.setHeaderNotification(true));
  }
}

export function* setAppRegion() {
  let region = Region.australia;

  if (window.location.origin === `https://${Region.newZealand.domain}`) {
    region = Region.newZealand;
  }

  yield put(appActions.setRegion(region));

  return region;
}

export function* checkAndSetCurrency() {
  const region = yield select(getRegion);

  if (region?.id === Region.newZealand.id) {
    yield put(appActions.setCurrency(Currency.nzd));
    return;
  }

  yield put(appActions.setCurrency(Currency.aud));
}

export function* initStripe() {
  const region = yield select(getRegion);
  let publicKey = process.env.REACT_APP_STRIPE_TOKEN_AUD;

  if (region?.id === Region.newZealand.id) {
    publicKey = process.env.REACT_APP_STRIPE_TOKEN_NZD;
  }

  window.stripe = yield call(loadStripe, publicKey);
}

function* listenCreateChatMessage() {
  try {
    const currentUserId = yield select(authSelectors.getUserId);
    const messageSentChannel = yield call(
      socket.createSocketChannel,
      socket.chatEvents.CHAT_MESSAGE_SENT,
    );

    while (true) {
      const { chat, message } = yield take(messageSentChannel);
      if (message.to === currentUserId) {
        yield put(appActions.incrementNewMessagesCount());
        const selectedChat = yield select(messagesPageSelectors.getSelectedChat);
        if (window.location.pathname.includes('messages')) {
          yield put(messagesPageActions.setChatLastMessage({ chat, message }));
          if (selectedChat.id === chat._id) {
            yield put(messagesPageActions.addMessage({ ...message, notificationId: undefined }));
            yield put(messagesPageActions.deleteNotification(message.notificationId));
          }
        } else if (selectedChat.id === chat._id) {
          yield put(messagesPageActions.addMessage({ ...message, notificationId: undefined }));
        }
      }
    }
  } catch (error) {
    yield put(appActions.showError(error.message));
  }
}

function* listenDeleteChatMessage() {
  try {
    const messageDeletedChannel = yield call(
      socket.createSocketChannel,
      socket.chatEvents.CHAT_MESSAGE_DELETED,
    );

    while (true) {
      const { chat, messageId, notificationId } = yield take(messageDeletedChannel);
      yield put(messagesPageActions.deleteNotification(notificationId));

      const selectedChat = yield select(messagesPageSelectors.getSelectedChat);
      if (selectedChat.id === chat._id) {
        yield put(messagesPageActions.deleteMessageSuccess(messageId));
      }
    }
  } catch (error) {
    yield put(appActions.showError(error.message));
  }
}

export function* checkNewMessages() {
  const currentUserId = yield select(authSelectors.getUserId);
  const signedIn = yield select(authSelectors.getIsUserAuthorized);
  if (!currentUserId || !signedIn) {
    yield cancel();
  }

  const result = yield call(apiService.notification.getCount);

  if (result.length > 0) {
    yield put(appActions.setNewMessagesCount(result[0].count));
  } else {
    yield put(appActions.setNewMessagesCount(0));
  }

  const listenCreateTask = yield fork(listenCreateChatMessage);
  const listenDeleteTask = yield fork(listenDeleteChatMessage);

  yield take((a) => a.type === authActionTypes.signOut.toString());

  yield cancel(listenCreateTask);
  yield cancel(listenDeleteTask);
  yield cancel();
}

export function* onAppInit() {
  try {
    const region = yield call(setAppRegion);
    yield call(checkAndSetCurrency);
    yield put(setIsSubscribed(storage.subscribe.get()));
    yield call(initStripe);
    yield call(checkAndSetCurrentUser);
    yield call(checkAndSetHeaderNotification);
    yield call(checkAndSetCookiesAccepted);
    yield call(fillSavedCreds);
    yield call(analytics.init, region);
    yield call(google.core.init);
    yield fork(checkNewMessages);
  } catch (e) {
    console.log(e);
  } finally {
    yield put(appActions.appInitComplete());
  }
}
