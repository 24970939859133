import { mobileHeaderHeightPx, mobileHeaderNotificationHeightPx } from 'common/constants/ui';
import { lighten } from '@material-ui/core/styles';

const styles = (theme) => ({
  defaultAppBar: {
    padding: '0px',
    zIndex: 1250,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 3px 14px 2px rgba(0, 0, 0, 0.06)',
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: mobileHeaderHeightPx,
  },
  logoIcon: {
    width: '64px',
    height: '32px',
    transform: 'scale(2)',
  },
  menuButton: {
    padding: '6px 7px',
    borderRadius: '40px',
    whiteSpace: 'nowrap',
    backgroundColor: lighten(theme.palette.primary.main, 0.95),
    marginLeft: theme.spacing(2),
  },
  listContainer: {
    marginTop: `calc(${mobileHeaderHeightPx} + ${mobileHeaderNotificationHeightPx})`,
  },
});

export default styles;
