import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { MapOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import { Divider, Menu } from '@evee/evee-ui.base';
import { getHomeUrl } from '@evee/evee-ui.utils';
import { history } from '@evee/evee-ui.services';

import supportHrefs from 'common/constants/supportHrefs';
import useAuth from 'store/modules/auth/hooks/useAuth';
import useHideWebWidget from 'common/hooks/useHideWebWidget';
import useRegion from 'store/modules/app/hooks/useRegion';

import Item from '../../utils/Item';

import {
  Bolt,
  Car,
  File,
  Gift,
  Headphones,
  Lightbulb,
  Notebooks,
  SignIn,
  UserPlus,
} from '../../icons';

import styles from '../styles';

const MainMenu = ({ anchorEl, open, classes, onClose }) => {
  const { onSignIn, onSignUp } = useAuth();
  const { region } = useRegion();
  const location = useLocation();

  useHideWebWidget(open);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      openDirection="bottom"
      classes={{ list: classes.container, paper: classes.paper }}
      onClose={onClose}
    >
      <Item
        bold
        label="Sign up"
        data-test-id="sign-up-button"
        icon={<UserPlus />}
        onClick={() => {
          onSignUp();
          onClose();
        }}
      />

      <Item
        label="Log in"
        data-test-id="log-in-button"
        icon={<SignIn />}
        onClick={() => {
          onSignIn();
          onClose();
        }}
      />

      <Item
        selected={location.pathname === '/car-host'}
        label="Become a host"
        icon={<Car />}
        onClick={() => {
          history.push('/car-host');
          onClose();
        }}
      />

      <Divider className={classes.divider} />

      <Item
        label="All evee vehicles"
        selected={location.pathname.includes('/vehicles') || location.pathname.includes('/search')}
        icon={<MapOutlined />}
        onClick={() => {
          history.push('/vehicles');
          onClose();
        }}
      />

      <Item
        label="How evee works"
        icon={<Lightbulb />}
        onClick={() => {
          window.location.href = `${getHomeUrl(region)}/renting`;
        }}
      />

      <Item
        label="Insurance"
        icon={<File />}
        onClick={() => {
          window.location.href = `${getHomeUrl(region)}/insurance`;
        }}
      />

      <Item
        label="Charging guide"
        icon={<Bolt />}
        onClick={() => {
          window.location.href = `${getHomeUrl(region)}/blog/your-evee-intro-guide-to-charging`;
        }}
      />

      <Item
        label="Gift cards"
        icon={<Gift />}
        onClick={() => {
          window.location.href = `${getHomeUrl(region)}/giftcards`;
        }}
      />

      <Divider className={classes.divider} />

      <Item
        label="Contact support"
        icon={<Headphones />}
        onClick={() => {
          window.location.href = supportHrefs[region.id].contactSupport;
        }}
      />

      <Item
        label="Help Centre"
        icon={<Notebooks />}
        onClick={() => {
          window.location.href = supportHrefs[region.id].helpCenter;
        }}
      />
    </Menu>
  );
};

MainMenu.propTypes = {
  anchorEl: PropTypes.object,
  open: PropTypes.bool,
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    paper: PropTypes.string.isRequired,
    menuItem: PropTypes.string.isRequired,
    divider: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func,
};

MainMenu.defaultProps = {
  anchorEl: {},
  open: false,
  onClose: () => {},
};

export default withStyles(styles)(MainMenu);
